// Dependencias
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

// Componentes
import CloseBtn from './CloseBtn';
import InputSearch from './InputSearch';
import ButtonSubmit from './ButtonSubmit';
import { getConfiguracion } from './api';

// Contexto
import { ParametrosBusquedaProvider } from './parametrosBusqueda';

// Datos
import { datosEntrada } from './constans';
import { language } from './language';

import {Liferay as LiferayAPI} from './common/services/liferay/liferay';
import apicommon from './common/services/liferay/apicommon';

import Observer from './Observer';

const MotorBusqueda = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const MotorBusqueda = useRef();
  const [urlTheme, setUrlTheme] = useState('');
  const [urlFlight, setUrlFlight] = useState('');

	useEffect(() => {
		async function getUrlTheme() {
			var newUrlTheme = await getConfiguracion("url-theme");
			setUrlTheme(newUrlTheme);
		};
		
		async function getUrlFlight() {
			var newUrlFlight = Liferay.ThemeDisplay.getLanguageId().split("_")[0];
			setUrlFlight(newUrlFlight);
		};
		
		if(!urlTheme)
			getUrlTheme();
			
		if(!urlFlight)
			getUrlFlight();
	}, []);
	
  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function openSearch() {
    setIsSearchOpen(true);
  }	

  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function closeSearch() {
    setIsSearchOpen(false);
  }

  /**
     * Funci�n que maneja el submit del formulario
     */
  function handleSubmit(e) {
    e.preventDefault();
  }

  // Ocultar el motor de b�squeda al llegar al final de la p�gina
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (!isSearchOpen) {
        if ((window.scrollY + window.innerHeight) >= (document.body.clientHeight - 200)) {
          MotorBusqueda.current?.classList.contains('hidden') ? null : MotorBusqueda.current.classList.add('hidden');
        } else {
          MotorBusqueda.current?.classList.contains('hidden') ? MotorBusqueda.current.classList.remove('hidden') : null;
        }
      }
    })
  })
  
  function openBookingtravel() {
  	window.open('https://bookingtravel.hotelsviva.com/' + urlFlight + "/");
  }
  
  function showExclusive(e) {
	e.preventDefault();
	$("#exclusive_content").css({
	    visibility: "visible",
	    opacity: "1",
	});
  }
  
  function closeExclusive() {
	$("#exclusive_content").css({
	    visibility: "hidden",
	    opacity: "0",
	});
  }
  
  function openMotor() {
		if (window.innerWidth > 768) {
			$("#bookingSearch").addClass("activeSearch");
		    $("#overalySearch").css({
		      visibility: "visible",
		      opacity: "0.8",
		    });
		    $("#closeOverlay").css({
		      visibility: "visible",
		    });
		}
	}
	
	function closeMotor() {
		$("#bookingSearch").removeClass("activeSearch");
		$("#overalySearch").css({
		    visibility: "hidden",
		    opacity: "0",
		});
		$("#closeOverlay").css({
		    visibility: "hidden",
		});
	}
	
	function showMobile() {
		$(".booking").css({
		    height: "100vh",
		    display: "block",
		    position: "fixed",
		    padding: "1.5rem"
		});

		$(".booking_content").css({
		    display: "block",
		});

		$(".btnBookingMobile").css({
		    display: "none",
		});
	}
	
	function closeMobile() {
		$("#bookingSearch").css("display", "none");
		$("#overalySearch").css({
		    display: "none",
		});
		$(".btnBookingMobile").css({
		    display: "block",
		});
		$("#closeOverlay").css({
		    display: "none",
		});
	}


  return (
    <ParametrosBusquedaProvider>
      <div class="bgBooking">
        <div class="bgBooking_Search" id="overalySearch" style={{visibility: 'hidden'}} onClick={closeMotor}></div>
        <a href="#" id="closeOverlay" style={{visibility: 'hidden'}} onClick={closeMotor}>
            <img src={urlTheme + "/assets/icons/uil-multiply.svg"} alt="" />
        </a>
      </div>
      <div className={isSearchOpen ? "booking activeSearch" : "booking"} id="bookingSearch" ref={MotorBusqueda} onClick={openMotor}>
        <div className="booking_content">
          <div class="mobileTitle">
                <p>{language["haz-tu-reserva"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                <a href="#" id="closeContentMobile" onClick={closeMobile}>
                    <img src={urlTheme + "/assets/icons/uil-times-circle.svg"} alt=""/>
                </a>
          </div>
          <div class="booking_content-Links">
                <div class="hotelVuelos">
                    <a href="#">{language["hotel"][Liferay.ThemeDisplay.getLanguageId()]}</a>
                    <a class="vueloPlus" href="#" onClick={openBookingtravel}><span class="icon iconVuelo"></span>{language["vuelo-hotel"][Liferay.ThemeDisplay.getLanguageId()]}</a>
                </div>
                <div class="exclusive">
                    <a id="showExclusive" href="#" onClick={(e) => showExclusive(e)}>{language["disfruta-ventajas-exclusivas"][Liferay.ThemeDisplay.getLanguageId()]} <span class="icon iconPlus"></span></a>

                    <div id="exclusive_content" class="exclusive_content">
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/Hand.svg"} alt=""/>
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["mejor-precio-online"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/discountExclusive.svg"} alt="" />
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["descuento-proximas-estancias"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/VIVA.svg"} alt=""/>
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["ventajas-programas-fidelizacion"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <a href="#" id="closeOverlayExclusive" onClick={closeExclusive}>
                            <img src={urlTheme + "/assets/icons/uil-times-circle.svg"} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
          <div className="form-row">
            {datosEntrada.map((dato) => <InputSearch key={dato.id} isSearchOpen={isSearchOpen} dato={dato} />)}
            <ButtonSubmit submitAction={handleSubmit} />
          </div>
        </div>
      </div>
      <div className="btnBookingMobile">
      	<a className="btn btn-booking" id="btnBookingMobile" href="#" onClick={showMobile}>{language["reservar"][Liferay.ThemeDisplay.getLanguageId()]}</a>
      </div> 
    </ParametrosBusquedaProvider>
  )
};

class WebComponent extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		
				createRoot(this).render(
					<MotorBusqueda
					/>,
					this
				);
	}
}

const ELEMENT_ID = 'liferay-hoteles-viva-motor-reserva';

if (!customElements.get(ELEMENT_ID)) {
	customElements.define(ELEMENT_ID, WebComponent);
}
