// Dependencias
import React from "react";

// Iconos
import { CloseIcon, LupaIcon, CalendarIcon, PersonasIcon, CodigoIcon, ArrowLeft, ArrowRight, CamaIcon, AddIcon, RemoveIcon, PapeleraIcon, CheckIcon, StarIcon } from "./Iconos.js";

// Dropdowns
import HotelesDestinos from "./HotelesDestinos.js";
import Calendario from "./Calendario.js";
import Habitaciones from "./Habitaciones.js";
import PromoCode from "./PromoCode.js";

import apicommon from './common/services/liferay/apicommon';

/**
 * Función que recibe el nombre del icono y devuelve el icono
 * @param {*} iconName 
 * @returns 
 */
export function getIcon(iconName) {
    switch (iconName) {
        case 'close':
            return <CloseIcon />
        case 'lupa':
            return <LupaIcon />
        case 'calendario':
            return <CalendarIcon />
        case 'personas':
            return <PersonasIcon />
        case 'codigo':
            return <CodigoIcon />
        case 'arrow-left':
            return <ArrowLeft />
        case 'arrow-right':
            return <ArrowRight />
        case 'cama':
            return <CamaIcon />
        case 'añadir':
            return <AddIcon />
        case 'eliminar':
            return <RemoveIcon />
        case 'papelera':
            return <PapeleraIcon />
        case 'check':
            return <CheckIcon />
        case 'star':
            return <StarIcon />
        default:
            return null
    }
}

	export async function getConfiguracion(key) {
		var promesaUrl = await apicommon('o/c/vivaconfiguracions/scopes/'+ Liferay.ThemeDisplay.getScopeGroupId() + '?fields=valor&filter=clave%20eq%20%27' + key + '%27')
		.catch((e) => console.error("Error en llamada url formulario: " + e));
		var final=await promesaUrl.json();
		var url= final.items[0].valor;
		return url; 
	}


/**
 * Función que recibe el nombre del dropdown y devuelve el dropdown
 * @param {*} dropdownID
 * @returns
 */
export function getDropdown(dropdownID) {
    switch (dropdownID.id) {
        case 1:
            return <HotelesDestinos dropdownName={dropdownID.name} />
        case 2:
            return <Calendario dropdownName={dropdownID.name} />
        case 3:
            return <Habitaciones dropdownName={dropdownID.name} />
        case 4:
        	return <PromoCode dropdownName={dropdownID.name} />
        default:
            return null
    }
}
