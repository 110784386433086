// Dependencias
import React, { useState, useContext, useEffect } from "react";
import { PropTypes } from 'prop-types';

// Datos
import Destinos from "./Destinos";
import { language } from './language';

// Componentes
import { destinosHoteles } from "./constans";

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

function HotelesDestinos({dropdownName}) {
    const [input, setInput] = useState('');
    const { dispatch } = useContext(ParametrosBusquedaContext);
    const [hotelSeleccionado, setHotelSeleccionado] = useState('');
    const [codSeleccionado, setCodSeleccionado] = useState('');
    
    useEffect(() => {
		
		$("#dropdownHotelSelect").ready(function () {
		  $(".dropdown-toggle").dropdown();
		  
		  if (window.innerWidth > 768) { // Se ejecuta solo en pantallas mayores a 768px
		  	$("#dropdownHotelSelect").click(function (e) {
		      openMotor(e);
		    });
		  }
		});
		
		function getHotelSeleccionado() {
			if(document.getElementById("codHotel")) {
				var codHotel = document.getElementById("codHotel").innerHTML;
				var destinosFilter = destinosHoteles.filter((destino) => destino.codigo == codHotel);
				if(destinosFilter.length > 0) {
					setHotelSeleccionado(destinosFilter[0].hotel);
					setCodSeleccionado(destinosFilter[0].codigo);
					document.getElementById("dropdownHotelSelect").value = destinosFilter[0].codigo;
					document.getElementById("dropdownHotelSelect").innerHTML = destinosFilter[0].hotel;
				}
			}
		};
		
		if(!hotelSeleccionado)
			getHotelSeleccionado();
	}, []);
    
    function openMotor(e) {
		e.preventDefault();

	    if (window.innerWidth > 768) {
	        $("#bookingSearch").addClass("activeSearch");

	        $("#overalySearch").css({
	            visibility: "visible",
	            opacity: "0.8",
	        });

	        $("#closeOverlay").css({
	            visibility: "visible",
	        });
	    }

	}

    return (
        <div className="dropdown">
        	<a className="dropdown-toggle text-ellipsis_hotel" href="#" role="button" id="dropdownHotelSelect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => openMotor(e)}>
        		{language["selecciona-hotel"][Liferay.ThemeDisplay.getLanguageId()]}
        	</a>
            <div class="dropdown-menu" aria-labelledby="dropdownHotelSelect">
            	{destinosHoteles.map((destino) => <Destinos key={destino.id} destino={destino} input={input} />)}
            </div>
        </div>
    )
}

export default HotelesDestinos;
