import React from 'react';

import { language } from './language';

// Datos de entrada necesarios del motor de búsqueda
export var datosEntrada = [
  {
    id: 1,
    name: language["selecciona-hotel"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'lupa',
    class: 'hotelSelect'
  },
  {
    id: 2,
    name: language["entrada-salida"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'calendario',
    class: 'dateRange'
  },
  {
    id: 3,
    name: language["n-personas"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'personas',
    class: 'Npersonas'
  },
  {
    id: 4,
    name: language["promo-code"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'codigo',
    class: 'promoCode'
  }
]

export const destinosHoteles = [
          {
            id: 1,
            hotel: 'VIVA CALA MESQUIDA RESORT & SPA',
            codigo: 'mesquidaresort',
            maxPersonas: 7,
            maxAdultos: 5,
            ninos: true
          },
          {
            id: 2,
            hotel: 'VIVA BLUE & SPA',
            codigo: 'vivablue',
            maxPersonas: 5,
            maxAdultos: 3,
            ninos: true
          },
          {
            id: 3,
            hotel: 'VIVA SUNRISE',
            codigo: 'sunrise',
            maxPersonas: 7,
            maxAdultos: 5,
            ninos: true
          },
          {
            id: 4,
            hotel: 'VIVA EDEN LAGO',
            codigo: 'edenlago',
            maxPersonas: 4,
            maxAdultos: 3,
            ninos: true
          },
          {
            id: 5,
            hotel: 'VIVA GOLF Adults only 18+',
            codigo: 'vanitygolf',
            maxPersonas: 3,
            maxAdultos: 3,
            ninos: false
          },
          {
            id: 6,
            hotel: 'VIVA  CALA MESQUIDA SUITES & SPA Adults only 16+',
            codigo: 'vanityhotel',
            maxPersonas: 3,
            maxAdultos: 3,
            ninos: false
          }
]
