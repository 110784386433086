// Dependencias
import React, { useEffect, useState, useContext } from "react";
import { language } from './language';

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

function Calendar({dropdownName}) {

    // Usar dispatch del contexto ParametrosBusquedaContext
    const { dispatch } = useContext(ParametrosBusquedaContext);
    
    $('.btn-picker-custom').on('click', function(ev, picker) {
	    //var fechaEntrada = new Date(picker.startDate._d);
	    //var fechaSalida = new Date(picker.endDate._d);
	    
	    //var fechaEntradaDia = fechaEntrada.getDate();
	    //var fechaEntradaMes = fechaEntrada.getMonth() + 1;
	   //var fechaEntradaAno = fechaEntrada.getFullYear();
	    
	    //var fechaSalidaDia = fechaSalida.getDate();
	    //var fechaSalidaMes = fechaSalida.getMonth() + 1;
	    //var fechaSalidaAno = fechaSalida.getFullYear();
	    
	    //var entrada = fechaEntradaAno + "-" + ((fechaEntradaMes < 10) ? "0" + fechaEntradaMes : fechaEntradaMes) + "-" + ((fechaEntradaDia < 10) ? "0" + fechaEntradaDia : fechaEntradaDia);
	    //var salida = fechaSalidaAno + "-" + ((fechaSalidaMes < 10) ? "0" + fechaSalidaMes : fechaSalidaMes) + "-" + ((fechaSalidaDia < 10) ? "0" + fechaSalidaDia : fechaSalidaDia);
	    //dispatch({
            //type: 'UPDATE_FECHAS', payload: {
                //entrada: entrada,
                //salida: salida
            //}
        //});
	});

    return (
        <input type="text" className="form-control" autoComplete="off" id="date-range" readonly="true" style={{height: "100%", backgroundColor: "inherit"}} placeholder={language["entrada-salida"][Liferay.ThemeDisplay.getLanguageId()]} onChange={(e) => handleChange(e.target.value)}/>
    )
}

export default Calendar