import React from 'react';

export const language = {
	"haz-tu-reserva": {
		"es_ES": "Haz tu reserva",
		"en_US": "Make your booking",
		"de_DE": "Machen Sie Ihre Reservierung"
	},
	"selecciona-hotel": {
		"es_ES": "Selecciona Hotel",
		"en_US": "Select Hotel",
		"de_DE": "Wählen Sie Hotel"
	},
	"entrada-salida": {
		"es_ES": "Entrada - Salida",
		"en_US": "Check-in - Check-out",
		"de_DE": "Anreise - Abreise"
	},
	"n-personas": {
		"es_ES": "Nº de personas",
		"en_US": "No. of people",
		"de_DE": "Anzahl der Personen"
	},
	"promo-code": {
		"es_ES": "Código promocional",
		"en_US": "Promo Code",
		"de_DE": "Promo-code"
	},
	"adultos": {
		"es_ES": "Adultos",
		"en_US": "Adults",
		"de_DE": "Erwachsene"
	},
	"ninos": {
		"es_ES": "Niños",
		"en_US": "Kids",
		"de_DE": "Kinder"
	},
	"edad-nino": {
		"es_ES": "Edad niño",
		"en_US": "Child age",
		"de_DE": "Alter des Kindes"
	},
	"hotel": {
		"es_ES": "Hotel",
		"en_US": "Hotel",
		"de_DE": "Hotel"
	},
	"vuelo-hotel": {
		"es_ES": "Vuelo + hotel",
		"en_US": "Flight + hotel",
		"de_DE": "Flug + Hotel"
	},
	"disfruta-ventajas-exclusivas": {
		"es_ES": "Disfruta de ventajas exclusivas reservando en nuestra web",
		"en_US": "Enjoy exclusive benefits by booking on our website",
		"de_DE": "Genießen Sie exklusive Vorteile, indem Sie auf unserer Website buchen"
	},
	"mejor-precio-online": {
		"es_ES": "Mejor precio online",
		"en_US": "Best price online",
		"de_DE": "Bester Preis online"
	},
	"descuento-proximas-estancias": {
		"es_ES": "Descuento en próximas estancias",
		"en_US": "Discount on future stays",
		"de_DE": "Rabatt auf zukünftige Aufenthalte"
	},
	"ventajas-programas-fidelizacion": {
		"es_ES": "Ventajas de nuestros programas de fidelización",
		"en_US": "Advantages of our loyalty programs",
		"de_DE": "Vorteile unserer Treueprogramme"
	},
	"reservar": {
		"es_ES": "Reservar",
		"en_US": "Book",
		"de_DE": "Reservieren"
	},
}