// Dependencias
import React, { useContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { language } from './language.js';

// Contexto
import { ParametrosBusquedaContext } from './parametrosBusqueda';
import { getConfiguracion } from './api';

function ButtonSubmit({ submitAction }) {
    const {
        submitSearchParameters
    } = useContext(ParametrosBusquedaContext);
	const [urlRoiback, setUrlRoiback] = useState('');
	
	useEffect(() => {
			async function getUrlRoiback() {
				var newUrlRoiback = await getConfiguracion("url-roiback-" + Liferay.ThemeDisplay.getLanguageId());
				setUrlRoiback(newUrlRoiback);
			};
			
			if(!urlRoiback)
				getUrlRoiback();
		}, []);

    /**
     * Handle click on submit button
     * @param {*} e 
     */
    async function handleSubmit(e) {
        submitAction(e);
		if(!urlRoiback || urlRoiback == "") {
			var urlRoi = "https://";
			
			if(Liferay.ThemeDisplay.getLanguageId()=="es_ES")
				urlRoi += "reservas.hotelsviva.com";
			else if(Liferay.ThemeDisplay.getLangugaeId()=="en_US")
				urlRoi += "booking.hotelsviva.com";
			else if(Liferay.ThemeDisplay.getLanguageId()=="de_DE")
				urlRoi += "reservierungen.hotelsviva.com";
			
			submitSearchParameters(urlRoi);
		} else {
        	submitSearchParameters(urlRoiback);
		}
    }

    return (
        <div className="btnBooking">
            <a
                className="btn btn-booking"
                onClick={(e) => handleSubmit(e)}
                disabled={false}
            >{language["haz-tu-reserva"][Liferay.ThemeDisplay.getLanguageId()]}</a>
        </div>
    )
}

ButtonSubmit.propTypes = {
    submitAction: PropTypes.func.isRequired
}

export default ButtonSubmit