// Dependencias
import React from "react"

// Componentes
import Calendar from "./Calendar";

function Calendario({dropdownName}) {
	
	function openMotor() {
		if (window.innerWidth > 768) {
			$("#bookingSearch").addClass("activeSearch");
		    $("#overalySearch").css({
		      visibility: "visible",
		      opacity: "0.8",
		    });
		    $("#closeOverlay").css({
		      visibility: "visible",
		    });
		}
	}
	
    return (
        <div id="dateRangeInput" className="input-group" onClick={openMotor}>
            <Calendar dropdownName={dropdownName} />
        </div>
    )
}

export default Calendario;