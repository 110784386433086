// Dependencias
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

// Datos
import { destinosHoteles } from './constans';

function Destinos({ destino }) {
	const { dispatch } = useContext(ParametrosBusquedaContext);

	function changeHotel(e) {
		e.preventDefault();
		
		var value = $(e.target).data("value");
		var text = $(e.target).text();
		// Actualizar el texto del dropdown-toggle con el valor seleccionado
		$("#dropdownHotelSelect").text(text);
		
		document.getElementById("dropdownHotelSelect").value = e.target.getAttribute("value");
		document.getElementById("dropdownHotelSelect").style.color = "";
        dispatch({ type: 'UPDATE_HOTEL', payload: {
        		coddestino: e.target.getAttribute("value"),
        		destino: e.target.text
        	}
        });
        
        var hotel = destinosHoteles.find((h) => h.codigo == e.target.getAttribute("value"));
        console.log(hotel);
        if(!hotel.ninos) {
        	document.getElementById("kidsCounter").style.display = "none";
        	var textoPersonas = document.getElementById("dropdownPersonas").innerHTML.slice(0, -1) + '0';
        	document.getElementById("dropdownPersonas").innerHTML = textoPersonas;
        } else {
        	document.getElementById("kidsCounter").style.display = "block";
        }
	}
	
    return (
    	<a value={destino.codigo} className="hotelSelectItem dropdown-item" href="#" onClick={changeHotel}>
    		{destino.hotel}
    	</a>
    )
}

Destinos.propTypes = {
    destino: PropTypes.object.isRequired
}

export default Destinos