// Dependencias
import React, { createContext, useReducer } from "react";
import { PropTypes } from "prop-types";

// Datos
import { destinosHoteles } from './constans';

// Crear contexto para los parámetros de búsqueda
export const ParametrosBusquedaContext = createContext();

const initialState = {
  destino: '',
  coddestino: '',
  entrada: '',
  salida: '',
  occupancies: [
    {
      adults: 2,
      children: 0,
      ages: 0
    }
  ],
  codpromo: ''
};

/**
 * Función para realizar una petición HTTP POST y obtener los hoteles con un body XML
 * @param {string} url - La URL de la API para obtener los hoteles
 * @param {string} xmlBody - El cuerpo del XML para la petición POST
 * @returns {Promise} Una promesa que se resuelve con los datos de los hoteles o se rechaza con un error
 */
async function obtenerHotelesConXML(url, xmlBody) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/xml',
        'Access-Control-Allow-Origin': '*'
      },
      body: xmlBody
    });

	console.log(response);
	
    if (!response.ok) {
      throw new Error('Error al obtener los hoteles');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


const reducer = (state, action) => {
  const { type: actionType, payload: actionPayload } = action;

  switch (actionType) {
    case 'UPDATE_HOTEL':
      return {
        ...state,
        destino: actionPayload.destino,
        coddestino: actionPayload.coddestino
      };
    case 'UPDATE_FECHAS':
      return {
        ...state,
        entrada: actionPayload.entrada,
        salida: actionPayload.salida
      };
    case 'UPDATE_OCUPACION':
      return {
        ...state,
        occupancies: actionPayload
      };
    case 'UPDATE_CODIGO':
      return {
        ...state,
        codpromo: actionPayload
      };
    case 'RESET':
      return initialState;
  }
}

// Crear provider para los parámetros de búsqueda
export function ParametrosBusquedaProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Función que devuelve el valor del hotel en el estado global
   * @returns {string} Valor de hotel en el estado global
   */
  function getHotel() {
  	if(state.destino === '' || state.coddestino === '') {
  		return;
  	} else {
	    return {
	    	coddestino: state.coddestino,
	    	destino: state.destino
	    };
    }
  }

  /**
   * Función que devuelve el valor de las fechas en el estado global
   * @returns {Object} Objeto con los valores de las fechas del estado global
   */
  function getFechas() {
    var dates = [];
    if (state.entrada === '' || state.salida === '') {
      return;
    } else {
      dates = [
        new Date(state.entrada),
        new Date(state.salida)
      ];
    }
    return {
      entrada: dates[0],
      salida: dates[1]
    };
  }

  /**
   * Función que devuelve el valor de la ocupación en el estado global
   * @returns {Object} Objeto con los valores de habitaciones, adultos y niños del estado global
   */
  function getOcupacion() {
    return state.occupancies;
  }

  /**
   * 
   * @returns {string} Valor de codigo del estado global
   */
  function getCodigo() {
    return state.codpromo;
  }

  /**
   * Función para borrar del estado global todos los parámetros de búsqueda
   */
  function clearParameters() {
    dispatch({ type: 'RESET' });
  }

  /**
   * Función para enviar los parámetros de búsqueda al servidor
   */
  function submitSearchParameters(urlRoiback) {
    console.log(state);
    
    if(!document.getElementById("dropdownHotelSelect").value || document.getElementById("dropdownHotelSelect").value == "") {
    	document.getElementById("dropdownHotelSelect").style.color = "red";
    	return;
    }
    
    if(state.coddestino == "" && document.getElementById("dropdownHotelSelect").value) {
    	state.coddestino = document.getElementById("dropdownHotelSelect").value;
    }
    
    var datesSelected = document.getElementById("date-range")?.value;
    if(datesSelected != ""){
	    const dates = datesSelected.split(" - ");
	      
	    var fechaEntrada = dates[0];
	    var fechaSalida = dates[1];
	    var entrada = fechaEntrada.split("/")[2] + "-" + fechaEntrada.split("/")[1] + "-" + fechaEntrada.split("/")[0];
	    if(!fechaSalida) {
	    	var tomorrow = new Date(entrada);
	    	tomorrow.setDate(tomorrow.getDate() + 1);
	    	var date = tomorrow.getDate();
	    	var month = tomorrow.getMonth() + 1;
	    	var year = tomorrow.getFullYear();
	    	fechaSalida = ((date < 10) ? "0" + date : date) + "/" + ((month < 10) ? "0" + month : month) + "/" + year;
	    }
	    
	    var salida = fechaSalida.split("/")[2] + "-" + fechaSalida.split("/")[1] + "-" + fechaSalida.split("/")[0];
	    
	    state.entrada = entrada;
	    state.salida = salida;
	    
	    var hotel = destinosHoteles.filter((h) => h.codigo == state.coddestino);
	    if(!hotel[0].ninos)
	    	state.occupancies[0].children = 0;
	    
	    window.location.href = urlRoiback + '/bookcore/availability/' + state.coddestino + "/" + state.entrada + "/" + state.salida + "/?cp=" + state.codpromo + "&rrc=1&adults=" + state.occupancies[0].adults + ((state.occupancies[0].children > 0) ? "&ninos=" + state.occupancies[0].children + "&children-ages=" + state.occupancies[0].ages.replaceAll(";",",") : "") + "&occupancies=" + encodeURIComponent(JSON.stringify(state.occupancies)) + "&occp=1";
    } else {
    	window.location.href = urlRoiback + '/bookcore/availability/tomorrow/' + state.coddestino + "/?cp=" + state.codpromo + "&rrc=1&adults=" + state.occupancies[0].adults + ((state.occupancies[0].children > 0) ? "&ninos=" + state.occupancies[0].children + "&children-ages=" + state.occupancies[0].ages.replaceAll(";",",") : "") + "&occupancies=" + encodeURIComponent(JSON.stringify(state.occupancies)) + "&occp=1";
    }
    
  }

  return (
    <ParametrosBusquedaContext.Provider value={{
      state,
      dispatch,
      getHotel,
      getFechas,
      getOcupacion,
      getCodigo,
      clearParameters,
      submitSearchParameters
    }}>
      {children}
    </ParametrosBusquedaContext.Provider>
  );
}

ParametrosBusquedaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};