// Dependencias
import React, { useState, useEffect, useContext } from "react";
import { language } from './language.js';

import { getConfiguracion } from './api';

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

// Datos
import { destinosHoteles } from './constans';

function Habitaciones({dropdownName}) {
	const { getOcupacion, dispatch } = useContext(ParametrosBusquedaContext);
	const [adults, setAdults] = useState(2);
	const [kids, setKids] = useState(0);
	const [ages, setAges] = useState([0,0,0,0,0,0]);
	const [urlTheme, setUrlTheme] = useState('');
	
	useEffect(() => {
		handleChangeKids(kids);
		var newAges = "";
		for(i = 0; i < kids; i++) {
			if(i != 0)
				newAges += ";";
			newAges += ages[i];
		}
		
		var inputOcupacion = document.getElementById("dropdownPersonas");
		inputOcupacion.innerHTML = language["adultos"][Liferay.ThemeDisplay.getLanguageId()] + ": " + adults + ", " + language["ninos"][Liferay.ThemeDisplay.getLanguageId()] + ": " + kids;
		
	    dispatch({
            type: 'UPDATE_OCUPACION', payload: [
            	{
	                adults: adults,
	                children: kids,
	                ages: newAges
                }
            ]
        });
	}, [adults, kids, ages]);
	
	useEffect(() => {
		
		$("#dropdownPersonas").ready(function () {
		  $(".dropdown-toggle").dropdown();
		  
		  if (window.innerWidth > 768) { // Se ejecuta solo en pantallas mayores a 768px
		  	$("#dropdownPersonas").click(function (e) {
		      openMotor(e);
		    });
		  }
		});
		
		async function getUrlTheme() {
			var newUrlTheme = await getConfiguracion("url-theme");
			setUrlTheme(newUrlTheme);
		};
		
		if(!urlTheme)
			getUrlTheme();
	}, []);
		
		function incrementarAdults() {
			var currentHotel = document.getElementById("dropdownHotelSelect").value;
			var hotel = destinosHoteles.filter((h) => h.codigo == currentHotel);
			if(hotel.length > 0) {
				if(adults < hotel[0].maxAdultos)
					setAdults(adults => adults + 1);
				if(!hotel[0].ninos)
					setKids(kids => 0);
			} else {
				if(kids + adults < 7 && adults < 5)
					setAdults(adults => adults + 1);
			}
		}
		
		function incrementarKids() {
			var currentHotel = document.getElementById("dropdownHotelSelect").value;
			var hotel = destinosHoteles.filter((h) => h.codigo == currentHotel);
			if(hotel.length > 0) {
				if(hotel[0].ninos) {
					if(kids + adults < hotel[0].maxPersonas)
						setKids(kids => kids + 1);
				}
			} else {
				if(kids + adults < 7)
					setKids(kids => kids + 1);
			}
		}
		
		function decrementarAdults() {
			if(adults > 1) {
				setAdults(adults => adults - 1);
			}
			
			var currentHotel = document.getElementById("dropdownHotelSelect").value;
			var hotel = destinosHoteles.filter((h) => h.codigo == currentHotel);
			if(hotel.length > 0) {
				if(!hotel[0].ninos)
					setKids(kids => 0);
			}
		}
		
		function decrementarKids() {
			if(kids > 0) {
				setKids(kids => kids - 1);
			}
		}
		
		function handleChangeKids(n) {
			for(i = 1; i <= 6; i++) {
				if(kids >= i) {
					document.getElementById("kidsAge" + i).style.display = "block";
				} else {
					document.getElementById("kidsAge" + i).style.display = "none";
				}
			}
		}
		
		function handleChange(e) {
			var kidsSelect = e.target.id;
			var posString = kidsSelect.replaceAll("kidsSelect", "");
			var pos = parseInt(posString);
			
			var array = [...ages];
			array[pos - 1] = e.target.value;
			setAges(array);
		}
		
		function openMotor(e) {
			e.preventDefault();
			if (window.innerWidth > 768) {
				$("#bookingSearch").addClass("activeSearch");
			    $("#overalySearch").css({
			      visibility: "visible",
			      opacity: "0.8",
			    });
			    $("#closeOverlay").css({
			      visibility: "visible",
			    });
			}
		}

    return (
        <div className="dropdown">
        	<a className="dropdown-toggle" href="#" role="button" id="dropdownPersonas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => openMotor(e)}>
                {language["n-personas"][Liferay.ThemeDisplay.getLanguageId()]}
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownPersonas" style={{padding: "1rem"}}>
            	<form>
            	<div className="adultsCounter">
                    <div className="adultsCounter_label">
                        <span>{language["adultos"][Liferay.ThemeDisplay.getLanguageId()]}</span>
                    </div>
                    <div className="adultsCounter_btn">
                        <button id="adultsDisminuirBoton" type="button" onClick={decrementarAdults}>
                            <img src={"https://liferayhotelesvivatheme-extn7m2hotelsviva-extprd.lfr.cloud/assets/icons/uil-minus-circle.svg"} alt=""/>
                        </button>

                        <input id="adultsNumber" value={adults} style={{border: "none"}} readonly="true"/>

                        <button id="adultsAumentarBoton" type="button" onClick={incrementarAdults}>
                            <img src={"https://liferayhotelesvivatheme-extn7m2hotelsviva-extprd.lfr.cloud/assets/icons/uil-plus-circle.svg"} alt=""/>
                        </button>
                    </div>
                </div>
                <div id="kidsCounter">
                <div className="kidsCounter">
                    <div className="kidsCounter_label">
                        <span>{language["ninos"][Liferay.ThemeDisplay.getLanguageId()]}</span>
                    </div>
                    <div className="kidsCounter_btn">
                        <button id="kidsDisminuirBoton" type="button" onClick={decrementarKids}>
                            <img src={"https://liferayhotelesvivatheme-extn7m2hotelsviva-extprd.lfr.cloud/assets/icons/uil-minus-circle.svg"} alt=""/>
                        </button>

                        <input id="kidsNumber" value={kids} style={{border: "none"}} readonly="true"/>

                        <button id="kidsAumentarBoton" type="button" onClick={incrementarKids}>
                            <img src={"https://liferayhotelesvivatheme-extn7m2hotelsviva-extprd.lfr.cloud/assets/icons/uil-plus-circle.svg"} alt=""/>
                        </button>
                    </div>
                </div>
                <div class="kidsAge" id="kidsAge1" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 1</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect1" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>

                <div class="kidsAge" id="kidsAge2" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 2</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect2" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                
                <div class="kidsAge" id="kidsAge3" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 3</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect3" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                
                <div class="kidsAge" id="kidsAge4" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 4</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect4" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                
                <div class="kidsAge" id="kidsAge5" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 5</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect5" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                
                <div class="kidsAge" id="kidsAge6" style={{display: "none"}}>
                  <div class="kidsAgeLabel">
                    <span class="icoKidsAge">{language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} 6</span>
                  </div>
                  <div class="kidsAgeSelect">
                    <select class="custom-select" id="kidsSelect6" onChange={handleChange}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                </div>
                </form>
            </div>
        </div>
    )
}

export default Habitaciones