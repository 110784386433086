// Dependencias
import React, { useEffect, useState, useContext } from "react";
import { language } from './language';

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

function PromoCode({dropdownName}) {

    // Usar dispatch del contexto ParametrosBusquedaContext
    const { dispatch } = useContext(ParametrosBusquedaContext);
    
    /**
     * Función para actualizar el valor del input editable
     * @param {*} input 
     */
    function handleInputChange(input) {
            dispatch({
                type: 'UPDATE_CODIGO',
                payload: input
            });
    }

    return (
        <input type="text" className="form-control" id="validationTooltip03" placeholder={language["promo-code"][Liferay.ThemeDisplay.getLanguageId()]} onChange={(e) => handleInputChange(e.target.value)}/>
    )
}

export default PromoCode